import React from "react";
import { Link } from "gatsby";

import { Grid, Cell } from "baseui/layout-grid";
import { Heading, HeadingLevel } from "baseui/heading";
import { Label1, Paragraph1 } from "baseui/typography";
import { StyledLink } from "baseui/link";
import { Accordion, Panel } from "baseui/accordion";
import { LocaleProvider } from "baseui";
import { Button, KIND, SIZE } from "baseui/button";
import { ChevronRight } from "baseui/icon";
import Layout from "../components/layout";
import SEO from "../components/seo";

import { ru_RU } from "../locale/ru_RU";

import { PROGRAMS } from "../data/programs";

const title = "Программы микрокредитования";

const Programs = () => {
  const list = PROGRAMS.map((program) => {
    const {
      id,
      name,
      maxAmount,
      interestRate,
      loanTerm,
      maxGracePeriod,
      isGraceDisabled,
      description,
      active,
    } = program;

    const selfLink = program?.links?.self;
    if (!active) return;
    return (
      <Panel title={`${name}`}>
        <HeadingLevel key={id.toString()}>
          <Heading styleLevel={4}>{name}</Heading>
          <Label1>
            до {(maxAmount || 5_000_000).toLocaleString("ru-RU")} руб.
          </Label1>
          <Paragraph1>
            Ставка: {interestRate.toLocaleString("ru-RU")}%, Срок: до{" "}
            {loanTerm || 24} мес., Льготный период:{" "}
            {isGraceDisabled
              ? "нет"
              : `до ${maxGracePeriod || (loanTerm || 24) - 1} мес.`}
          </Paragraph1>
          <Label1>Участники программы</Label1>
          <Paragraph1>{description}</Paragraph1>
          <Paragraph1>
            {selfLink && (
              <Button
                $as={Link}
                to={selfLink.href}
                kind={KIND.secondary}
                size={SIZE.default}
                endEnhancer={<ChevronRight size={20} />}
              >
                Подробнее
              </Button>
            )}
          </Paragraph1>
        </HeadingLevel>
      </Panel>
    );
  });
  return (
    <Layout>
      <SEO title={title} />
      <HeadingLevel>
        <Grid>
          <Cell span={8}>
            <Heading>{title}</Heading>
            <LocaleProvider locale={ru_RU}>
              <Accordion renderAll>{list}</Accordion>
            </LocaleProvider>
          </Cell>
          <Cell span={4}>
            <HeadingLevel>
              <Heading>Документы</Heading>
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/file/d/1rEMQ0TA9mPbv2cTsuwUsXnrmY3_u0sji/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Правила предоставления микрозаймов
                </StyledLink>
              </Paragraph1>
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/drive/folders/1EbpEUXV9XXffSCCH68KLBnG8kbeG-4bb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Предыдущие редакции правил предоставления микрозаймов
                </StyledLink>
              </Paragraph1>
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/file/d/1l5kZDY3Volcqq4Dmxzc9QaB-b9WeyYOu/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Программы Фонда
                </StyledLink>
              </Paragraph1>
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/file/d/1mlRYKA3Trz5m3Jcz-JUi7Tc-RImNc7Dt/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Программа "Акселерация субъектов МСП в моногородах"
                  [Завершена]
                </StyledLink>
              </Paragraph1>
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/file/d/1HS50Gl7LSs5EQ0xdQcilmal-XDcA_HgL/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Программа "5-5-10"
                </StyledLink>
              </Paragraph1>
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/file/d/1jekgAzrcNOqG2iPrR1m_Y1Xai4jb8kMj/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Программа "Самозанятые граждане - 2022" [Завершена]
                </StyledLink>
              </Paragraph1>
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/file/d/1Er2iZEgU3UlWk1lxphvtDC_L6Z8If61U/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Программа "Самозанятые граждане - 2021" [Завершена]
                </StyledLink>
              </Paragraph1>
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/file/d/1j6ZoUBIXG5k8Y4DT5ISYvEkBD-rBwacL/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Программа "Антикризисные меры" [Завершена]
                </StyledLink>
              </Paragraph1>
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/file/d/1f9j_pFYV8BvggLfH0HceHPWt-lhFVoPN/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Постановление Правительства РФ от 10.03.2022 №337
                </StyledLink>
              </Paragraph1>
            </HeadingLevel>
          </Cell>
        </Grid>
      </HeadingLevel>
    </Layout>
  );
};

export default Programs;
